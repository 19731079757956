

























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { calendarStoreGetter, calendarStoreMutations } from '@/store/calendar.store';

const CalendarStore = namespace('calendar');

/**
 * Context menu for ConvoySchedules
 */
@Component({})
export default class CalendarBaseContextMenuComponent extends Vue {

    @CalendarStore.Mutation(calendarStoreMutations.SET_SHOW_CONTEXT_MENU)
    private setShowContextMenu!: (payload: boolean) => void;

    @CalendarStore.Getter(calendarStoreGetter.SHOW_CONTEXT_MENU)
    private _showContextMenu!: boolean;

    public get showContextMenu(): boolean {
        return this._showContextMenu;
    }

    public set showContextMenu(value: boolean) {
        this.setShowContextMenu(value);
    }

    @CalendarStore.Mutation(calendarStoreMutations.SET_SHOW_CREATE_CONTEXT_MENU)
    private setShowCreateContextMenu!: (payload: boolean) => void;

    @CalendarStore.Getter(calendarStoreGetter.SHOW_CREATE_CONTEXT_MENU)
    private _showCreateContextMenu!: boolean;

    public get showCreateContextMenu(): boolean {
        return this._showCreateContextMenu;
    }

    public set showCreateContextMenu(value: boolean) {
        this.setShowCreateContextMenu(value);
    }

    @CalendarStore.Getter(calendarStoreGetter.GENERAL_CONTEXT_MENU_POSITION)
    private _generalContextMenuPosition!: { x: number, y: number };

    public get generalContextMenuPosition(): { x: number, y: number } {
        return this._generalContextMenuPosition;
    }
}
